@import "animate.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "react-day-picker/dist/style.css";

.rdp {
  margin: 0;
}

.rdp {
  /* Size of the day cells. */
  --rdp-cell-size: 30px;
  --rdp-caption-font-size: 15px; /* Font size for the caption labels. */

  /* Accent color for the background of selected days. */
  --rdp-accent-color: rgb(229, 245, 248);

  /* Background color for the hovered/focused elements. */
  --rdp-background-color: rgb(127, 209, 222);

  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */

  /* Outline border for focused elements */
  /* --rdp-outline: 2px solid var(--rdp-accent-color); */
  --rdp-outline: none;

  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: rgb(51, 71, 91); /* Color of selected day text */
}

.rdp-day {
  border-radius: 0;
  border: none;
  height: 30px;
  width: 37px;
  max-width: 37px;
  color: #33475b;
}

div[data-day-picker-mode="single"] .rdp-day {
  border-radius: 3px;
}
div[data-day-picker-mode="range"]:hover .rdp-day {
  border-radius: 0;
}

.rdp-day:hover {
  background-color: rgb(127, 209, 222);
}

.rdp-day_selected:not(.rdp-day_range_middle),
.rdp-day_selected:not(.rdp-day_range_middle):hover {
  color: #fff;
}

.rdp-head_cell {
  color: rgb(81, 111, 144);
  padding: 10px 0;
  height: auto;
}

.rdp-day_outside {
  color: rgb(203, 214, 226);
}
.rdp-day_outside:hover {
  color: rgb(229, 245, 248);
}

.rdp-button:hover:not([disabled]):not(
    .rdp-day_range_start,
    .rdp-day_range_end,
    .rdp-day_selected
  ) {
  background-color: var(--rdp-background-color);
}

.rdp-day_range_middle {
  border-radius: 0;
}
.rdp-day_range_in_next_range {
  background-color: var(--rdp-accent-color);
  border-radius: 0;
}

.rdp-button.rdp-day_range_start,
.rdp-button.rdp-day_range_end,
.rdp-day_selected:not(.rdp-day_range_middle) {
  background-color: rgb(0, 164, 189);
  color: #fff;
}

.rdp-button.rdp-day_range_start {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.rdp-button.rdp-day_range_end {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rdp-day_today {
  overflow: visible;
  color: rgb(230, 130, 80);
}
.rdp-day_today::before {
  content: " ";
  display: inline-block;
  position: absolute;
  border: 2.5px solid currentcolor;
  border-radius: 50%;
  top: 22px;
  left: 16px;
}

.rdp-day_disabled:not(.rdp-day_range_start, .rdp-day_range_end)::after {
  content: " ";
  display: inline-block;
  position: absolute;
  inset: 12px 13px 0px 2px;
  border-top: 1px solid currentcolor;
  transform: rotate(45deg);
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: inherit;
}

.austin-red {
  color: red;
}

.austin-red4 {
  color: red;
}
